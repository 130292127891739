import {CommentOutlined, LoadingOutlined, SendOutlined} from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import React, {useState, useEffect, useContext, useRef} from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {diffDate, emojis} from 'shared/extra';
import {Button} from 'antd';
import moment from 'moment';
import loaders from '../../shared/delivery-truck.gif'

function makeLinkClickable(inputString) {
  if (inputString.startsWith("http://") || inputString.startsWith("https://")) {
    return <a style={{ textDecoration: "underline"}} href={inputString}target="_blank">{inputString}</a>;
  } else {
    return <p style={{margin:0,paddingBottom:4}}>{inputString}</p>;
  }
}


const UserChatPanel = ({
  messageHere,
  sendMessage,
  showConversation,
  newListeMessage,
  keys,
  chatBoxRef,
  bg,
  handleClick,
  setNewMessage,
  newMessage,
  divRef,
  profileImg,
  sound,
  emptyConversation,
  setemptyConversation,
  channelId,
  loadingChange,
  totalM, setPageM,
  getUserPerMsg,
  setLoaderScroll,
  loaderScroll
  
}) => {
 const [messageClicked,setMessageClicked]=useState(null)
   const [ListeMessageInner,setListeMessageInner]=useState(newListeMessage)
 
  const playSound = () => {
    sound.play();
  };

  useEffect(() => {
    setListeMessageInner(newListeMessage)
  },[newListeMessage])
  





 const chatBoxRefscrol =  useRef();
console.log("setLoaderScroll",loaderScroll);

  const handleScroll = async () => {
    const divElement = chatBoxRefscrol.current;
  
    if (divElement) {
      
   
   
   const isAtBottom =(
   divElement.scrollHeight + (Math.round(divElement.scrollTop))) - divElement.clientHeight<=1;
      
      
       
      
        // console.log("tttt----M ",isAtBottom); 
        // console.log("totalM ",totalM); 
        // console.log("newListeMessage-", newListeMessage.length); 
      if (isAtBottom && (totalM > newListeMessage?.length)) {
        ;
        getUserPerMsg()
       
        setPageM(pre=>pre+1)

        // console.log('boyom');
        

        // Do something when scrolled to the bottom
      }
    }
  };


  useEffect(() => {
   
    const divElement = chatBoxRefscrol.current;

    if (divElement) {
      // Attach the scroll event listener
      divElement.addEventListener('scroll', handleScroll);

      // Remove the event listener when the component is unmounted
      return () => {
        divElement.removeEventListener('scroll', handleScroll);
      };
    }
  });























  
  if (loadingChange) {
    return (<div
      className="show-demande-sub-container-right-msg"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        animation: 'fadeIn 1s',
      }}  
    >
    <LoadingOutlined width={"50px"}/></div>)
  } else return (
    <>
      {(ListeMessageInner.length > 0  || !emptyConversation)? (
      
        <div className="show-demande-sub-container-right-msg">
          <Col lg={12}>
            <Row lg={12}>
              <Card
                //  key={keys}
                style={{
                  animation: 'fadeIn .1s',
                  boxShadow: 'none',
                  margin: '5px 1px 10px 1px',
                  width: '99%',
                  height: '700px',
                  border: '1px solid #80DEEA',
                  backgroundColor:"#ffb30008",
                  // backgroundImage: 'urL(' + bg + ')',
                  boxShadow: 'inset gray 0px 0px 9px -5px',
                }}>
                <div
                  ref={chatBoxRefscrol}
                  style={{
                    height: '100%',
                    margin: '5px',
                    padding: '10px',
                    overflowY: 'auto',
                    display: 'flex',
                    // border:"1px solid red",
                    justifyContent:"end",
                    flexDirection: 'column-reverse',
                  }}>
                   {loaderScroll&&<LoadingOutlined
                    style={{
                      position: "absolute",
                      top: 50,
                      zIndex:10,
                      right: "47%",
                      background:"orange",
                                  marginLeft:"10px",
                                  color: 'orange',
                                  fontSize: '37px',
                                  fontWeight: 700,
                                }}
                              />}
                  {ListeMessageInner
                    ? ListeMessageInner.map((data, i) => {
                        if (data.from_user == false) {
                          return (
                            <div
                              // ref={chatBoxRef}
                              key={i}
                              className="self-right-msg-container">
                              <div
                                style={{position:"relative"}}
                                key={i * 2} className="msg">
                                <p
                                  // key={keys}
                                  onClick={()=>!messageClicked||messageClicked!=data._id?setMessageClicked(data._id):setMessageClicked(null)}
                                  style={{
                                    cursor:"pointer",
                                    animation: 'fadeIntranslate .5s',
                                    backgroundColor: '#FFECD6',
                                    padding: '10px',
                                    margin: '0 0 0 3px',
                                    maxWidth:"500px",
                                    borderTopRightRadius: '25px',
                                    borderTopLeftRadius: '25px',
                                    borderBottomLeftRadius: '25px',
                                    boxShadow: 'black 0px 0px 11px -6px',
                                  }}>
                                  {makeLinkClickable(data.message)}
                                </p>
                              {messageClicked&&messageClicked==data._id&&  <p
                                      key={keys}
                                  style={{
                                    // position: "absolute",
                                    // bottom: "-45px",
                                        animation: 'fadeIn 1s',
                                        // border:"1px solid red",
                                        fontSize: '12px',
                                        color: 'black',
                                        backgroundColor: 'white',
                                        borderRadius: '5px',
                                        margin:"5px 5px",
                                        padding: '1px 5px',
                                      }}>
                                      {moment(data.createdAt).format(
                                        ' DD-MM-YYYY - HH:mm',
                                      )}{' '}
                                    </p>}
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <>
                              {/* {diffDate(new Date(), data.createdAt) >= 1 &&
                                data.from_user == true && (
                                  <div
                                    key={i * 10}
                                  style={{
                                    animation: 'fadeIntranslate .5s',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      marginTop: '50px',
                                    }}>
                                    <p
                                      key={keys}
                                      style={{
                                        animation: 'fadeIn 1s',
                                        border:"1px solid red",
                                        fontSize: '10px',
                                        color: 'black',
                                        backgroundColor: 'white',
                                        borderRadius: '5px',
                                        padding: '1px 5px',
                                      }}>
                                      {moment(data.createdAt).format(
                                        ' DD-MM-YYYY - HH:mm',
                                      )}{' '}
                                    </p>
                                  </div>
                                )} */}
                              <div
                                key={i * 100}
                                className="self-left-msg-container">
                                <div key={i * 55} ref={divRef} className="msg" style={{
                                  // border: "1px solid red",
                                  // paddingBottom:'30px',
                                  "align-items": "flex-start"
                                }}>
                                  <img
                                   key={i * 3}
                                    className="rounded-image-small"
                                    src={profileImg}></img>
                                  <div
                                    
                                    onClick={()=>!messageClicked||messageClicked!=data._id?setMessageClicked(data._id):setMessageClicked(null)}
                                    
                                    style={{
                                      position:"relative",
                                      cursor:"pointer",
                                      animation: 'fadeIntranslate 1s',
                                      borderTopLeftRadius: '0px',
                                      backgroundColor: '#FF9800',
                                      padding: '5px',
                                      opacity: 1,
                                      display: "flex",
                                      alignItems: "flex-end",
                                      flexDirection: "column",
                                      maxWidth:"500px",
                                      margin: '2px 0 0 5px',
                                      color: 'black',
                                      border:"1px solid green",
                                      borderBottomLeftRadius: '25px',
                                      borderBottomRightRadius: '25px',
                                      borderTopRightRadius: '25px',
                                      boxShadow: 'black 0px 0px 11px -6px',
                                    }}>
                                    {makeLinkClickable(data.message)}
                                    
                                  </div>
                                  {messageClicked&&messageClicked==data._id&&<p
                                      key={keys}
                                    style={{
                                      // position: 'absolute',
                                      // bottom: -45,
                                      // left: "0",
                                        animation: 'fadeIn 1s',
                                        // border:"1px solid red",
                                        fontSize: '10px',
                                      color: 'black',
                                        fontSize:"12px",
                                        backgroundColor: 'white',
                                        borderRadius: '5px',
                                        padding: '1px 7px',
                                        margin:"1px 0 0px 0"
                                      }}>
                                      {moment(data.createdAt).format(
                                        ' DD-MM-YYYY - HH:mm',
                                    )}{' '}
                                    </p>}  
                                </div>
                              </div>
                            </>
                          );
                        }
                      })
                    : 'no message yet'}
                </div>
              </Card>
            </Row>
            <Row>
              <div
                style={{
                  display: 'flex',
                  // alignItems: 'center',
                  fontSize: '10px',
                  width: '100%',
                  margin: '5px 0px',
                  padding: '5px',
                  // border: '1px solid red',
                  gap: 10,
                  overflow: 'scroll',
                  // justifyContent: "space-between",
                }}>
                {' '}
                {emojis.map(item => (
                  <span
                    key={item.id}
                    style={{
                      userSelect: 'none',
                      fontSize: '2em',
                      margin: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleClick(item.emoji);
                      playSound();
                    }}>
                    {item.emoji}
                  </span>
                ))}
              </div>
            </Row>
            <Row style={{}}>
              <div
                style={{
                  padding: 0,
                  margin: 0,
                  display: 'flex',
                  // alignItems: 'center',
                  width: '100%',
                  // border: '1px solid red',
                  gap: 10,
                  // justifyContent: "space-between",
                }}>
                <TextArea
                  value={newMessage}
                  onChange={e => setNewMessage(e.target.value)}
                  rows={20}
                  style={{
                    flex: '20',
                    fontSize: '17px',
                    height: '70px',
                  }}
                  placeholder="Ecrire un message ..."
                  maxLength={5000}
                  onKeyDown={(event) => {
                    // console.log(event);
                    
                    if (event.key === 'Enter') {
                      sendMessage(showConversation.id, channelId);
                    }
                  }}
                />
                <Button
                  style={{
                    flex: '1',
                    padding: 0,
                    height: '70px',
                    backgroundColor: 'orange',
                    display: 'flex',
                    fontSize: '30px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transform: !newMessage ? 'rotate(90deg)' : 'rotate(0deg)',
                  }}
                  onClick={() => {
                    sendMessage(showConversation.id,channelId);
                  }}
                 
                  disabled={!newMessage ? true : false}
                  type="primary">
                  <SendOutlined />
                </Button>
                &nbsp; &nbsp;
              </div>
            </Row>
          </Col>
        </div>
      ) : (
        <div
          className="show-demande-sub-container-right-msg"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            animation: 'fadeIn 1s',
          }}>
            <div
             style={{ fontSize: "50px" }} 
            >

            <CommentOutlined height={100} width={100} />
            </div>
            {/* <img
            style={{
              width: '20%',

              opacity: 0.2,
            }}
            src={messageHere}
          /> */}
          <p className="p" style={{fontSize: '17px'}}>
            Pas encore des messages avec{' '}
            <span style={{fontWeight: 700}}>
              {showConversation.firstName} {showConversation.lastName}
            </span>{' '}
            <div style={{textAlign: 'center'}}>
              Vous recevrez votre message ici!
            </div>
          </p>
          <Button
            onClick={() => {
              setemptyConversation(false)
            }}
            style={{marginTop: '20px', color: 'orange'}}>
            Démarrer une conversation
          </Button>
        </div>
      )}
    </>
  );
};

export default UserChatPanel;


import axios from 'axios';
const CenterFormApi = axios.create({
  baseURL: process.env.REACT_APP_CENTERFORM_API,
  
});

// CenterFormApi.interceptors.request.use(
//   config => {
//     if (localStorage.getItem('TOKEN')) {
//       config.headers.common['Authorization'] =
//         'Bearer ' + localStorage.getItem('TOKEN');
//     }
//     return config;
//   },
//   error => {
//      return Promise.reject(error.message);
//   },
// );



CenterFormApi.interceptors.response.use(
  response => {
    // Handle successful responses
    return response;
  },
  error => {
    if (error.response) {
      // Server-side error (e.g., 401, 404, 500)
      console.error('Response error:', error.response.data);
      if (error.response.status === 401) {
        // Handle unauthorized, e.g., redirect to login
        console.warn('Unauthorized! Redirecting to login.');
        localStorage.removeItem('TOKEN'); // Optional: Clear token
        window.location.href = '/login'; // Redirect to login page
      }
    } else if (error.request) {
      // Network error or no response received
      console.error('No response received:', error.request);
    } else {
      // Other errors (e.g., request setup issue)
      console.error('Error setting up request:', error.message);
    }
    return Promise.reject(error); // Reject with the full error object
  }
);


// CenterFormApi.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     if (error.message.indexOf(401) !== -1) {
//       localStorage.removeItem('TOKEN');
//       window.location = '/login';
//     }

//     return Promise.reject(error);
//   },
// );

export default CenterFormApi;
